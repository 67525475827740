<template>
  <div
    class="c-action-bar__item c-action-bar__item--print"
  >
    <ActionBarItem
      :text="model.printItemLabel"
      :icon="iconPrint"
      :icon-active="iconPrint"
      :show-title="showTitle"
      :icon-hover-color="'medium-gray'"
      @click="onClick"
    />
  </div>
</template>

<script>
import iconPrint from '@ds/svg/icons/stroke/print-text.svg'
import ActionBarItem from './ActionBarItem.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    ActionBarItem
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconPrint
    }
  },
  methods: {
    onClick() {
      this.trackData('printPosition')
      setTimeout(() => {
        window.print()
      }, 200)
    },
    trackData(type) {
      tracker.trackActionBar({
        event: type,
        position: this.showTitle ? 'sticky' : 'top'
      })
    }
  }
}


</script>

<template>
  <div class="c-action-bar u-flex">
    <SaveAction
      v-if="model.save"
      v-model="store.state.isFavorite"
      :model="model.save"
      :show-title="showTitles"
      :popover-position="popoverPosition"
      :popover-position-mobile="popoverPositionMobile"
      :tracking-obj="saveTracking"
    />
    <PrintAction
      :model="model.print"
      :show-title="showTitles"
    />

    <ShareAction
      :model="model.share"
      :show-title="showTitles"
      :popover-position="popoverPosition"
      :popover-position-mobile="popoverPositionMobile"
      tracking-name="sharePosition"
    />
  </div>
</template>

<script>
import store from '@/CVI/Recipe/store'

import SaveAction from './SaveAction.vue'
import ShareAction from './ShareAction.vue'
import PrintAction from './PrintAction.vue'


export default {
  components: {
    SaveAction,
    ShareAction,
    PrintAction
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    showTitles: {
      type: Boolean,
      default: false
    },
    popoverPosition: {
      type: String,
      default: 'bottom'
    },
    popoverPositionMobile: {
      type: String,
      default: 'bottom'
    },
    buyButton: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      store,
      saveTracking: {
        event: 'savePosition'
      }
    }
  },
  computed: {
    isFavorite: {
      get() {
        return this.store.state.isFavorite
      },
      set(val) {
        this.store.state.isFavorite = val
      }
    }
  },
  created() {
    if (this.model.save) {
      this.store.state.isFavorite = this.model.save.isFavorite
    }
  }
}
</script>
